import { SUBMIT_REDEEMFORM , SUBMIT_REDEEMFORM_RESPONSE, RESET_SUBMIT_REDEEMFORM } from '../actions/index';

export default function order(state = {
    isFetching: false,
    status:'',
    error: null,
    message:''
  }, action) {
        switch (action.type) {
            case SUBMIT_REDEEMFORM:
                return {...state, isFetching: true, status: 'pending',  error: null}
            case SUBMIT_REDEEMFORM_RESPONSE:
                if(action.payload.data.status === 200) {
                    return {...state, isFetching: false, status:'success', message:action.payload.data.message}
                }
                else if (action.payload.data.status === 500) {
                    return {...state, isFetching: false, status:'error', message:action.payload.data.message}
                }
                break;
            case RESET_SUBMIT_REDEEMFORM:
                return {...state, isFetching: false, status: '',  error: null, message:''}
            default:
                return state
        }
  }